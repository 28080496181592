import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { mobile } from '~styles/global'
import { useCart } from '~context/siteContext'
import resolveLink from '~utils/resolveLink'
import Image from '~components/Image'
import Incrementer from '~components/Incrementer'
import prepareCheckout from '~utils/prepareCheckout'
import { LargeCross, CircleCross } from '~components/Svg'
import Button from '~components/Button'
import SanityLink from '~components/SanityLink'

const Item = ({ variant }) => {

	const { updateQty, removeItem } = useCart()

	return(
		<LineItem>
			<LineMain>
				<ImageLink 
					link={{
						linkType: 'internal', 
						document: {
							store: {
								slug: variant.slug,
							},
							_type: 'product'
						}
					}}
				>
					<Img src={variant?.store?.previewImageUrl ?? variant?.productImage} />
				</ImageLink>
				<Details>
					<Title 
						className='h6' 
						link={{
							linkType: 'internal', 
							document: {
								store: {
									slug: variant.slug,
								},
								_type: 'product'
							}
						}}
					>
						{variant?.store?.title !== 'Default Title' ? variant?.store?.title : variant?.productTitle}
					</Title>
					<UnitDescription className='med-ital'>Case of 24</UnitDescription>
					<Price className='h6'>${variant?.store?.price.toFixed(2)}</Price>
					<IncrementerMobile
						small
						onChange={value => updateQty(variant.store?.id, value)}
						initialValue={variant.qty}
						maxValue={variant.maxQty}
					/>
				</Details>
			</LineMain>
			<IncrementerDesktop 
				small
				onChange={value => updateQty(variant.store?.id, value)}
				initialValue={variant.qty}
				maxValue={variant.maxQty}
			/>
			<Remove onClick={() => removeItem(variant.store?.id)}><StyledCircleCross/></Remove>
		</LineItem>
	)
}

const LineItem = styled.div`
  display: grid;
	grid-column-gap: var(--l);
	grid-template-columns: 1fr max-content max-content;
	padding: var(--s) 0;
	border-bottom: 1px solid;
	${mobile}{
		display: flex;
		justify-content: space-between;
		grid-column-gap: 0;
	}
`
const ImageLink = styled(SanityLink)`
	display: block;
`
const Img = styled.img`
	border-radius: 10px;
	width: 150px;
	${mobile}{
		width: 90px;
	}
`
const LineMain = styled.div`
	display: grid;
	grid-column-gap: 25px;
	grid-template-columns: max-content 1fr;
`
const Details = styled.div`
	
`
const Title = styled(SanityLink)`
	margin-bottom: 8px;
	display: block;
	margin-top: var(--xs);
	${mobile}{
		margin-top: 0;
	}
`
const UnitDescription = styled.div`
	margin-bottom: 11px;
`
const Remove = styled.button`
	width: 37px;
	height: 37px;
	min-width: 37px;
`
const StyledCircleCross = styled(CircleCross)`
	
`
const Price = styled.div`
	${mobile}{
		margin-bottom: 11px;
	}
`
const IncrementerDesktop = styled(Incrementer)`
	${mobile}{
		display: none;
	}
`
const IncrementerMobile = styled(Incrementer)`
	display: none;
	${mobile}{
		display: block;
	}
`

Item.propTypes = {
	variant: PropTypes.object,
}

const Cart = ({ open, className }) => {

	const { cartTotal, cart, closeCart } = useCart()
	const [loading, setLoading] = useState(false)

	const loadCheckout = () => {
		setLoading(true)
		prepareCheckout(cart)
	}

	return(
		<>
			{open &&
				<Global styles={css`
					html{
						overflow-y: hidden;
					}
				`}/>
			}
			<Wrap 
				className={className}
				css={css`
          transform: ${open ? 'translateX(0)' : 'translateX(100%)'};
          pointer-events: ${open ? 'all' : 'none'};
        `}
			>
				<div>
					<Head>
						<div>Your cart</div>
						<Close onClick={() => closeCart()}>
							<Cross />
						</Close>
					</Head>
					{cart.length ? 
						cart.map(variant => (
							<Item variant={variant} key={variant?.store?.id}/>
						))
						:
						<Empty className='h5'>
            Your Cart is Empty
						</Empty> 
					}
				</div>
				{cart.length ? 
					<Total>
						<div className='h6'>
							<Subtotal>Subtotal</Subtotal>
							${cartTotal().toFixed(2)}
						</div>
						<Button small onClick={() => loadCheckout(cart)}>{loading ? 'Loading...' : 'Check out'}</Button>
					</Total>
					:

					<KeepShopping small onClick={() => closeCart()}>
          	Keep Shopping
					</KeepShopping>
				}
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
  position: fixed;
  z-index: ;
  width: 720px;
  top: 0;
  right: 0;
  bottom: 0;
	z-index: 101;
  transition: transform 0.5s;
  box-sizing: border-box;
	background: var(--darkBlue);
	border-left: 1px solid;
	padding: 0 var(--m);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  ${mobile}{
    width: calc(100% + 1px);
  }
`
const Head = styled.div`
	padding: var(--xl) 0 var(--l);
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid;
`
const Close = styled.button`
	
`
const Cross = styled(LargeCross)`
	width: 18px;
`
const Empty = styled.p`
	margin-top: var(--l);
`
const Subtotal = styled.div`
	padding-bottom: 4px;
`
const Total = styled.div`
	display: flex;
	padding-top: var(--s);
	justify-content: space-between;
	align-items: center;
	margin-bottom: var(--xl);
	border-top: 1px solid;
`
const KeepShopping = styled(Button)`
	margin-bottom: var(--xl);
`

Cart.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
}

export default Cart
